.custom-dots .slick-dots {
  bottom: 10px !important;
}

.custom-dots .slick-dots li button {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.react-multi-carousel-dot--active {
    button {
        background: #f7744b !important;
        border-radius: 5px;
        width: 20px !important;
    }
} 

.react-multi-carousel-dot {
    button {
        border: none !important;
        width: 13px;
    }
}