.slick-prev, .slick-next {
    display: block !important;
    z-index: 10; /* Garante que as setas fiquem acima de outros elementos */
}

.slick-prev:before, .slick-next:before {
    color: #130B46 !important;
    font-size: 30px;
}

.slick-track {
    display: flex !important;
    justify-content: flex-start !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.slick-prev {
    left: -28px;
}
.slick-next {
    right: -18px;
}
