.carousel__arrow_container{
	position: absolute;
	z-index: 999;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&.left{
		top: 0;
		left: -10px;
	}

	&.right{
		top: 0;
		right: -10px
	}
}

.carousel__arrow{
	background: #130B46 !important;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	outline: none;
	border: none;
	cursor: pointer;

	&:disabled{
		opacity: 0.4;
	}
}