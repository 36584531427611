@keyframes spinner-breath {
    0% {
        animation-timing-function: cubic-bezier(0.9744, 0.2389, -0.0807, 0.7946);
        transform: scale(0.78);
    }
    51% {
        animation-timing-function: cubic-bezier(0.941, 0.2589, -0.0463, 0.7671);
        transform: scale(1.15981);
    }
    100% {
        transform: scale(0.78);
    }
}