.ubq-public {
  --ubq-canvas: #f3f5fb;
  --ubq-Topbar-background: #f3f5fb;
  --ubq-InspectorBar-background: white;
  --ubq-elevation-2: white;
  --ubq-interactive-hover: #f4f4f4;
  --ubq-margin-xs: 8px;
  --ubq-scale-base: 6.4px;
  --ubq-stroke-contrast-1: lightgray;

  --ubq-typography-button-m-font_family: var(--cesdk-font_family);
  --ubq-typography-body-m-font_family: var(--cesdk-font_family);
  --ubq-typography-input-m-font_family: var(--cesdk-font_family);
  --ubq-typography-label-s-font_family: var(--cesdk-font_family);
  --ubq-typography-label-m-font_family: var(--cesdk-font_family);
  --ubq-typography-headline-m-font_family: var(--cesdk-font_family);
  --ubq-typography-headline-l-font_family: var(--cesdk-font_family);
}
